import React, { useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const firebaseConfig = {
    apiKey: "AIzaSyDbvm9_2fGM0rg1jk_3diPOjznBXtiHieQ",
    authDomain: "door-closers-90dca.firebaseapp.com",
    projectId: "door-closers-90dca",
    storageBucket: "door-closers-90dca.appspot.com",
    messagingSenderId: "866575330941",
    appId: "1:866575330941:web:4277e3d5c22f00ab15cb14",
    measurementId: "G-9WWZMMMBDX",
  };

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        if (location.pathname !== "/") {
          navigate("/");
        }
      }
    });

    return () => unsubscribe();
  }, [navigate, location.pathname, auth]);

  return <>{children}</>;
};

export default AuthProvider;
