import React, { useState } from "react";
import Card from "./components/card";
import logo from "./assets/img/logo.png";
import {
  getAuth,
  signInWithEmailAndPassword,
} from "https://www.gstatic.com/firebasejs/10.8.1/firebase-auth.js";
import { initializeApp } from "https://www.gstatic.com/firebasejs/10.8.1/firebase-app.js";

const firebaseConfig = {
  apiKey: "AIzaSyDbvm9_2fGM0rg1jk_3diPOjznBXtiHieQ",
  authDomain: "door-closers-90dca.firebaseapp.com",
  projectId: "door-closers-90dca",
  storageBucket: "door-closers-90dca.appspot.com",
  messagingSenderId: "866575330941",
  appId: "1:866575330941:web:4277e3d5c22f00ab15cb14",
  measurementId: "G-9WWZMMMBDX",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export default function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleSignIn = async (e) => {
    e.preventDefault();

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email.trim(),
        password
      );
      const user = userCredential.user;

      console.log("Signed in user:", user);
      localStorage.setItem("user", JSON.stringify(user));
      window.location.href = "/admin/Tickets";
    } catch (error) {
      setError("Invalid email or password. Please try again.");
      console.error("Error signing in:", error);
    }
  };

  return (
    <Card className="cardSign mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center">
      <div className="max-w-50 mt-[10vh] w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <form onSubmit={handleSignIn}>
          <img src={logo} alt="Logo" />

          <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
            Sign In
          </h4>
          <p className="mb-9 ml-1 text-base text-gray-600">
            Enter your email and password to sign in!
          </p>

          <div className="mb-6 flex items-center gap-3">
            <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
            <p className="text-base text-gray-600 dark:text-white"></p>
            <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
          </div>

          <input
            variant="auth"
            extra="mb-3"
            label="Email*"
            placeholder="mail@simmmple.com"
            id="email"
            type="text"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <input
            variant="auth"
            extra="mb-3"
            label="Password*"
            placeholder="Min. 8 characters"
            id="password"
            type="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          {error && <p className="mb-4 text-red-500">{error}</p>}

          <button className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
            Sign In
          </button>
        </form>
      </div>
    </Card>
  );
}
