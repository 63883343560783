import React, { useState, useEffect } from "react";
import { initializeApp } from "https://www.gstatic.com/firebasejs/10.8.1/firebase-app.js";
import {
  getFirestore,
  collection,
  getDocs,
} from "https://www.gstatic.com/firebasejs/10.8.1/firebase-firestore.js";
import Card from "components/card";

const OutsourcingMessages = () => {
  const [tasks, setTasks] = useState([]);

  const firebaseConfig = {
    apiKey: "AIzaSyDbvm9_2fGM0rg1jk_3diPOjznBXtiHieQ",
    authDomain: "door-closers-90dca.firebaseapp.com",
    projectId: "door-closers-90dca",
    storageBucket: "door-closers-90dca.appspot.com",
    messagingSenderId: "866575330941",
    appId: "1:866575330941:web:4277e3d5c22f00ab15cb14",
    measurementId: "G-9WWZMMMBDX",
  };

  useEffect(() => {
    const app = initializeApp(firebaseConfig);
    const database = getFirestore(app);
    const fetchData = async () => {
      console.log("0");
      try {
        const querySnapshot = await getDocs(
          collection(database, "submissions")
        );
        const tasksArray = [];
        querySnapshot.forEach((doc) => {
          for (const taskId in doc.data()) {
            const task = {
              id: taskId,
              ...doc.data()[taskId],
            };
            tasksArray.push(task);
          }
        });
        tasksArray.sort((a, b) =>
          a.Email && b.Email ? a.Email.localeCompare(b.Email) : 0
        );
        setTasks(tasksArray);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="mt-5">
      <Card extra="w-full pb-10 p-4 h-full">
        <header className="flex items-center justify-between">
          <h2 className="text-xl font-bold text-navy-700 dark:text-white">
            Messages
          </h2>
        </header>

        <div className="mt-8 overflow-x-auto">
          <table className="w-full whitespace-nowrap">
            <thead>
              <tr className="bg-gray-100 dark:bg-gray-800">
                <th className="py-4 px-8 text-left">Name</th>
                <th className="py-4 px-8 text-left">Email</th>
                <th className="py-4 px-8 text-left">Phone</th>
                <th className="py-4 px-8 text-left">Subject</th>
                <th className="py-4 px-8 text-left">Company</th>
                <th className="py-4 px-8 text-left">Message</th>
              </tr>
            </thead>
            <tbody>
              {tasks.map((task) =>
                task.page === "outsourcing" ? (
                  <tr key={task.id} className="border-b dark:border-gray-700">
                    <td className="py-4 px-8">{task.name}</td>
                    <td className="py-4 px-8">{task.email}</td>
                    <td className="py-4 px-8">{task.phone}</td>
                    <td className="py-4 px-8">{task.subject}</td>
                    <td className="py-4 px-8">{task.company}</td>
                    <td className="py-4 px-8">{task.message}</td>
                  </tr>
                ) : null
              )}
            </tbody>
          </table>
        </div>
      </Card>
    </div>
  );
};

export default OutsourcingMessages;
