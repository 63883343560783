import React, { useState } from "react";
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  doc,
  setDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore";

import Card from "components/card";

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyDbvm9_2fGM0rg1jk_3diPOjznBXtiHieQ",
  authDomain: "door-closers-90dca.firebaseapp.com",
  projectId: "door-closers-90dca",
  storageBucket: "door-closers-90dca.appspot.com",
  messagingSenderId: "866575330941",
  appId: "1:866575330941:web:4277e3d5c22f00ab15cb14",
  measurementId: "G-9WWZMMMBDX",
};

const app = initializeApp(firebaseConfig);
const database = getFirestore(app);

const TicketSystem = () => {
  const [formType, setFormType] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    projectName: "",
    projectType: "",
    projectDescription: "",
    suggestedETA: "",
  });

  const handleRadioChange = (e) => {
    setFormType(e.target.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const {
      email,
      name,
      phone,
      projectName,
      projectType,
      projectDescription,
      suggestedETA,
    } = formData;
    const docRef = doc(collection(database, "ticketSystem"));
    await setDoc(docRef, {
      email,
      name,
      phone,
      projectName,
      projectType,
      projectDescription,
      suggestedETA,
      submittedTime: "",
      devETA: "",
      progress: "",
    });
    setFormData({
      name: "",
      email: "",
      phone: "",
      projectName: "",
      projectType: "",
      projectDescription: "",
      suggestedETA: "",
    });
  };

  return (
    <div className="mt-5 grid h-full grid-cols-1 gap-5 ">
      <Card extra={"w-full pb-10 p-4 h-full"}>
        <header className="relative flex items-center justify-between">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            Tickets
          </div>
        </header>
        <div className="mt-8 overflow-x-scroll ">
          <div className="form-group custom-radio">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="companyType"
                id="activeCampaign"
                value="activeCampaign"
                onChange={handleRadioChange}
              />
              <label className="form-check-label" htmlFor="activeCampaign">
                ActiveCampaign
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="companyType"
                id="newTicket"
                value="newTicket"
                onChange={handleRadioChange}
              />
              <label className="form-check-label" htmlFor="newTicket">
                New Ticket
              </label>
            </div>
          </div>

          {formType === "newTicket" && (
            <div id="newTicketForm">
              <form className="form-container" onSubmit={handleSubmit}>
                <header>New Ticket</header>
                <div className="custom-input-container">
                  <input
                    type="text"
                    name="name"
                    className="custom-input"
                    placeholder="Name:"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="custom-input-container">
                  <input
                    type="email"
                    name="email"
                    className="custom-input"
                    placeholder="Email:"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="custom-input-container">
                  <input
                    type="tel"
                    name="phone"
                    className="custom-input"
                    placeholder="Phone:"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="custom-input-container">
                  <input
                    type="text"
                    name="projectName"
                    className="custom-input"
                    placeholder="Project Name:"
                    value={formData.projectName}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="custom-input-container">
                  <select
                    name="projectType"
                    className="custom-input2"
                    value={formData.projectType}
                    onChange={handleChange}
                    required
                  >
                    <option value="" disabled selected>
                      Project Type:
                    </option>
                    <option value="Website Development">
                      Website Development
                    </option>
                    <option value="Platform Services">Platform Services</option>
                    <option value="CRM Services">CRM Services</option>
                    <option value="Webhook/API Services">
                      Webhook/API Services
                    </option>
                    <option value="Google Apps">Google Apps</option>
                    <option value="Scripting">Scripting</option>
                    <option value="Reporting Functions">
                      Reporting Functions
                    </option>
                  </select>
                </div>
                <div className="custom-input-container">
                  <textarea
                    name="projectDescription"
                    className="custom-input textarea"
                    placeholder="Project Description:"
                    value={formData.projectDescription}
                    onChange={handleChange}
                    required
                  ></textarea>
                </div>
                <div className="custom-input-container">
                  <input
                    type="date"
                    name="suggestedETA"
                    className="custom-input"
                    placeholder="Initial Deadline:"
                    value={formData.suggestedETA}
                    onChange={handleChange}
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                >
                  Submit
                </button>
              </form>
            </div>
          )}
          {formType === "activeCampaign" && (
            <ActiveCampaignForm database={database} />
          )}
        </div>
      </Card>
    </div>
  );
};

const ActiveCampaignForm = ({ database }) => {
  const [email, setEmail] = useState("");
  const [tickets, setTickets] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const q = query(
      collection(database, "ticketSystem"),
      where("Email", "==", email)
    );
    const querySnapshot = await getDocs(q);
    const ticketsData = querySnapshot.docs.map((doc) => doc.data());
    setTickets(ticketsData);
  };

  return (
    <div id="activeCampaignForm">
      <form className="form-container" onSubmit={handleSubmit}>
        <header>Active Campaign</header>
        <div className="activecampaign" id="formContainer">
          <div className="custom-input-container">
            <input
              type="email"
              name="email"
              className="custom-input"
              placeholder="Email:"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <button
            type="submit"
            className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          >
            Submit
          </button>
        </div>
      </form>
      {tickets.length > 0 && (
        <div id="tableContainer">
          <div className="table-responsive">
            <h2>Tickets</h2>
            <table id="table" className="table-dc">
              <thead className="table-dark">
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Project Name</th>
                  <th scope="col">Project Type</th>
                  <th scope="col">Project Description</th>
                  <th scope="col">Initial Deadline</th>
                  <th scope="col">Developer Deadline</th>
                  <th scope="col">Task Progress</th>
                  <th scope="col">Submitted Date</th>
                </tr>
              </thead>
              <tbody id="taskTableBody">
                {tickets.map((ticket, index) => (
                  <tr key={index}>
                    <td>{ticket.name}</td>
                    <td>{ticket.email}</td>
                    <td>{ticket.phone}</td>
                    <td>{ticket.projectName}</td>
                    <td>{ticket.projectType}</td>
                    <td>{ticket.projectDescription}</td>
                    <td>{ticket.suggestedETA}</td>
                    <td>{ticket.devETA}</td>
                    <td>
                      <div className="progress">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: `${ticket.progress}%` }}
                          aria-valuenow={ticket.progress}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          {ticket.progress}%
                        </div>
                      </div>
                    </td>
                    <td>{ticket.submittedTime}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default TicketSystem;
