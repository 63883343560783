import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import AuthProvider from "./AuthProvider";

import AdminLayout from "layouts/admin";
import SignIn from "SignIn";
const App = () => {
  return (
    <>
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path="admin/*" element={<AdminLayout />} />
            <Route path="/" element={<SignIn />} />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </>
  );
};

export default App;
